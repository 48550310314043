import React, { useMemo, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { songs } from "../songs";
import { Chords } from "../components/Chords";
import { useKeepScreenAwake } from "../hooks/useKeepScreenAwake";
import { Actions } from "../components/Actions";

export const Song = () => {
  const { id } = useParams();
  const song = useMemo(() => songs.find((song) => song.id === id), [id]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const entriesCount = song?.entries.length ?? 0;

  const goTo = (index: number) => {
    const element = document.getElementById(`entry-${index}`);

    element?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const handleNext = () => {
    const nextIndex = Math.min(currentIndex + 1, entriesCount - 1);
    setCurrentIndex(nextIndex);
  };

  const handlePrevious = () => {
    const previousIndex = Math.max(currentIndex - 1, 0);
    setCurrentIndex(previousIndex);
  };

  useKeepScreenAwake();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setCurrentIndex(0);
  }, []);

  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      const forward = ["ArrowRight", "Space", "ArrowDown"];
      const backward = ["ArrowLeft", "ArrowUp"];

      if (forward.includes(event.code)) {
        handleNext();
      }

      if (backward.includes(event.code)) {
        handlePrevious();
      }
    };

    window.addEventListener("keyup", callback);

    return () => window.removeEventListener("keyup", callback);
  }, [handleNext]);

  useEffect(() => {
    goTo(currentIndex);
  }, [currentIndex]);

  if (!song) {
    document.title = "Song not found!";
    return <h1>Song not found!</h1>;
  }

  document.title = `${song.artist} — ${song.title} • Lyrics`;

  return (
    <div>
      <h1>{song.artist}</h1>
      <h2>{song.title}</h2>
      {song.capo > 0 && <h3>Capo {song.capo}</h3>}

      {song.entries.map((entry) => (
        <div
          key={entry.index}
          className="entry"
          data-current-entry={currentIndex === entry.index}
          id={`entry-${entry.index}`}
          onClick={() => setCurrentIndex(entry.index)}
        >
          {entry.label ? <h3>{entry.label}</h3> : null}

          {(entry.text || entry.label)
            .split(/\r?\n/)
            .map((line, lineNumber) => (
              <p key={`entry-${entry.index}-${lineNumber}`}>{line}</p>
            ))}
        </div>
      ))}

      <div className="entry" data-current-entry="false">
        <h3>End</h3>
      </div>

      <Chords capo={song.capo} chords={song.chords} />

      <Actions>
        <Link className="button" title="Go Back" to="/">
          Go Back
        </Link>
      </Actions>
    </div>
  );
};
