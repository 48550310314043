import chordsDB, { Position } from "@tombatossals/chords-db/lib/guitar.json";

const transformName = (input: string) =>
  input.replace(/#/gm, "sharp").replace("/", "_");

const chordsList = Object.keys(chordsDB.chords).flatMap((chordKey) =>
  chordsDB.chords[chordKey].map(({ key, suffix, positions }) => ({
    label: `${key}${suffix}`
      .replace("sharp", "#")
      .replace("major", "")
      .replace("minor", "m"),
    image: `/images/chords/${transformName(key)}/${transformName(
      suffix,
    )}/1.svg`,
    positions,
  })),
);

chordsList.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));

export const chords: {
  [key: string]: { label: string; image: string; positions: Position[] };
} = chordsList.reduce(
  (buffer, chord) => Object.assign(buffer, { [chord.label]: chord }),
  {},
);
