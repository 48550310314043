import React, { useContext, useEffect, useState } from "react";
import { Theme } from "./context/Theme";

export const App: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const contextTheme = useContext(Theme);
  const [theme, setTheme] = useState<"dark" | "light">(
    (localStorage.getItem("theme") ?? contextTheme) as "dark" | "light",
  );

  useEffect(() => {
    document.documentElement.dataset.theme = theme;
  }, [theme]);

  useEffect(() => {
    const callback = () => {
      const newTheme = localStorage.getItem("theme");

      if (newTheme) {
        setTheme(newTheme as "dark" | "light");
      }
    };

    window.addEventListener("storage", callback);

    return () => window.removeEventListener("storage", callback);
  }, []);

  useEffect(() => {
    const callback = ({ matches: isDark }: MediaQueryListEvent) => {
      if (!localStorage.getItem("theme")) {
        setTheme(isDark ? "dark" : "light");
      }
    };

    const matcher = window.matchMedia("(prefers-color-scheme: dark)");

    matcher.addEventListener("change", callback);

    return () => matcher.removeEventListener("change", callback);
  }, []);

  return <Theme.Provider value={theme}>{children}</Theme.Provider>;
};
