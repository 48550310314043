import React from "react";
import { Link } from "react-router-dom";
import { songs } from "../songs";
import { Actions } from "../components/Actions";

export const Home = () => {
  document.title = "Lyrics";

  return (
    <div>
      <h1 className="heading">Lyrics</h1>
      <p className="description">Because knowing the words actually matters!</p>

      <ol>
        {songs.map((song) => (
          <li key={song.id}>
            <Link to={`/lyrics/${song.id}`}>
              {song.artist} — {song.title}
            </Link>
          </li>
        ))}
      </ol>

      <Actions />
    </div>
  );
};
