import { useEffect, useRef } from "react";
import { debug } from "../helpers/debug";

export function useKeepScreenAwake() {
  const lock = useRef<WakeLockSentinel | null>(null);

  useEffect(() => {
    const handler = async () => {
      try {
        if (document.visibilityState === "hidden") {
          debug("releasing wake lock");
          await lock.current?.release();
          lock.current = null;
        } else {
          debug("requesting wake lock");
          lock.current = await navigator.wakeLock.request("screen");
        }
      } catch (error) {
        debug("error requesting/releasing wake lock");
        lock.current = null;
      }
    };

    document.addEventListener("visibilitychange", handler);
    handler();

    return () => document.removeEventListener("visibilitychange", handler);
  }, [lock]);

  useEffect(
    () => () => {
      debug("releasing wake lock");
      lock.current?.release().then(() => {
        lock.current = null;
      });
    },
    [lock],
  );
}
