import React from "react";
import { ThemeSwitcher } from "../components/ThemeSwitcher";

export const Actions: React.FC<{ children?: React.ReactElement }> = ({
  children,
}) => (
  <div className="actions">
    <ThemeSwitcher />

    {Boolean(children) && children}
  </div>
);
