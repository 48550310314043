import React from "react";
import { Link } from "react-router-dom";
import { Actions } from "../components/Actions";
import { chords } from "../config/chords";

export const Chords = () => (
  <div>
    <h1>Chords</h1>
    <Actions>
      <Link className="button" title="Go Back" to="/">
        Go Back
      </Link>
    </Actions>

    <div className="chords">
      {Object.values(chords).map((chord) => (
        <div key={chord.label} className="chord">
          <h2>{chord.label}</h2>
          <img src={chord.image} />
        </div>
      ))}
    </div>
  </div>
);
