import React from "react";
import { Chord } from "../typings";
import { chords as allChords } from "../config/chords";

type Props = {
  capo: number;
  chords: Chord[];
};

export const Chords: React.FC<Props> = ({ chords }) => {
  if (!chords.length) {
    return null;
  }

  return (
    <div className="chords--section">
      {chords.map((block, index) => (
        <div key={`chord-block-${index}`} className="chord-block">
          <h3>{block.label}</h3>
          <div className="chords">
            {block.chords.map((chord) => (
              <div key={chord} className="chord">
                <h2>{chord}</h2>
                {Boolean(allChords[chord]) && (
                  <img src={allChords[chord].image} />
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
