import React, { useContext } from "react";
import { Theme } from "../context/Theme";

export const ThemeSwitcher = () => {
  const theme = useContext(Theme);

  const handleSwapTheme = () => {
    localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
    dispatchEvent(new Event("storage"));
  };

  return (
    <button className="theme-button" onClick={handleSwapTheme}>
      {theme === "dark" ? "☀️" : "🌑"}
    </button>
  );
};
